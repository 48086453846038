<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-card :loading="loading">
      <v-tabs v-model="tab">
        <v-tab>{{ $store.getters.translate("edit") }}</v-tab>
        <v-tab @click="preview('nl')">{{ $store.getters.translate("preview_nl") }}</v-tab>
        <v-tab @click="preview('en')">{{ $store.getters.translate("preview_en") }}</v-tab>
        <v-toolbar dense flat>
          <v-spacer/>
          <v-btn v-if="canDelete" @click="archive" text small color="secondary">
            <v-icon color="error">mdi-archive</v-icon>
          </v-btn>
          <v-btn v-if="canEdit" text small @click="save" color="primary">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tab-item>
          <v-row>
            <v-col cols="2">
              <v-card flat>
                <v-card-text>
                  <template v-for="field in usual_fields">
                    <base-field
                        :key="field.name"
                        :value="record[field.name]"
                        :field="field"
                        @change="usualFieldDataChanged"/>
                  </template>
                  <template v-for="field in custom_fields">
                    <base-field
                        :key="field.name"
                        :value="record.custom_fields[field.name]"
                        :field="field"
                        @change="customFieldDataChanged"/>
                  </template>
                  <v-row class="mb-5">
                    <v-col>
                      <v-text-field
                        dense
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        :label="$store.getters.translate('margin_top')"
                        v-model="record.margin_top"
                        suffix="mm"/>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        :label="$store.getters.translate('margin_bottom')"
                        v-model="record.margin_bottom"
                        suffix="mm"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        :label="$store.getters.translate('margin_left')"
                        v-model="record.margin_left"
                        suffix="mm"/>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        :label="$store.getters.translate('margin_right')"
                        v-model="record.margin_right"
                        suffix="mm"/>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5">
                    <v-col>
                      <v-btn text color="primary" @click="showHeader" block>{{ $store.getters.translate("header") }}</v-btn>
                      <BaseDraggableModal
                          :name="'modal_header'"
                          max-height="80vh"
                          width="70vw"
                          :fullscreen="$vuetify.breakpoint.xsOnly"
                          ref="modal_header">
                        <v-card>
                          <BaseModal>
                            <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
                              <v-btn icon @click.stop="hideHeader">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ $store.getters.translate("header") }}</v-toolbar-title>
                            <v-spacer/>
                              <v-btn @click.stop="save" text small>
                              <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                          </v-toolbar>
                            <div slot="content">
                              <v-divider/>
                              <v-card-text>
                                <v-tabs>
                                  <v-tab>{{ $store.getters.translate("header_nl") }}</v-tab>
                                  <v-tab>{{ $store.getters.translate("header_en") }}</v-tab>
                                  <v-tab-item>
                                    <base-editor v-model="record.header_nl" height="500"/>
                                  </v-tab-item>
                                  <v-tab-item>
                                    <base-editor v-model="record.header_en" height="500"/>
                                  </v-tab-item>
                                </v-tabs>
                              </v-card-text>
                            </div>
                          </BaseModal>
                        </v-card>
                      </BaseDraggableModal>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5">
                    <v-col>
                      <v-btn text color="primary" @click="showFooter" block>{{ $store.getters.translate("footer") }}</v-btn>
                      <BaseDraggableModal
                          :name="'modal_footer'"
                          max-height="80vh"
                          width="70vw"
                          :fullscreen="$vuetify.breakpoint.xsOnly"
                          ref="modal_footer">
                        <v-card>
                          <BaseModal>
                            <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
                              <v-btn icon @click.stop="hideFooter">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ $store.getters.translate("footer") }}</v-toolbar-title>
                            <v-spacer/>
                              <v-btn @click.stop="save" text small>
                              <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                          </v-toolbar>
                            <div slot="content">
                              <v-divider/>
                              <v-card-text>
                                <v-tabs>
                                  <v-tab>{{ $store.getters.translate("footer_nl") }}</v-tab>
                                  <v-tab>{{ $store.getters.translate("footer_en") }}</v-tab>
                                  <v-tab-item>
                                    <base-editor v-model="record.footer_nl" height="500"/>
                                  </v-tab-item>
                                  <v-tab-item>
                                    <base-editor v-model="record.footer_en" height="500"/>
                                  </v-tab-item>
                                </v-tabs>
                              </v-card-text>
                            </div>
                          </BaseModal>
                        </v-card>
                      </BaseDraggableModal>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="mb-5">{{ $store.getters.translate("background_image") }}</span>
                      <v-file-input
                        v-if="!record.background_image"
                        accept="image/*"
                        :label="$store.getters.translate('background_image')"
                        show-size
                        @change="saveImage"
                        v-model="file"/>
                      <div class="text-center" v-else>
                        <v-img :src="record.background_image.full_path" max-height="150" contain style="border: 1px solid lightgrey"/>
                        <v-btn @click="deleteImage" text color="red" class="mt-2">
                          <v-icon color="error">mdi-trash-can</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <base-log-field :model_type="$models[page.name]" :model_id="record.id"/>
                      <base-tags v-if="tags_required" v-model="record.tags" :module_name="page.name"/>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-divider vertical/>
            <v-col>
              <v-card flat>
                <v-card-text>
                  <v-tabs>
                    <v-tab @click="setLanguage('nl')">{{ $store.getters.translate("nl") }}</v-tab>
                    <v-tab @click="setLanguage('en')">{{ $store.getters.translate("en") }}</v-tab>
                    <v-tab @click="getCategories">{{ $store.getters.translate("forms") }}</v-tab>
                    <v-tab @click="createForm">{{ $store.getters.translate("create_form") }}</v-tab>
                    <v-tab-item>
                      <base-editor class="mb-5" ref="rte_main_nl" v-model="record.html_nl" :margins="margins" height="1200"/>
                    </v-tab-item>
                    <v-tab-item>
                      <base-editor class="mb-5" ref="rte_main_en" v-model="record.html_en" :margins="margins" height="1200"/>
                    </v-tab-item>
                    <v-tab-item>
                      <v-data-table
                          :headers="form_headers"
                          :items="forms"
                          :loading="loading"
                          selectable-key="id"
                          hide-default-footer>
                        <template v-slot:item.id="{ item }">
                          <a @click="loadForm(item)">{{ item.id }}</a>
                        </template>
                        <template v-slot:item.name="{ item }">
                          <a @click="loadForm(item)">{{ item.name }}</a>
                        </template>
                      </v-data-table>
                      <br/>
                      <br/>
                      <v-expansion-panels v-model="panel" multiple>
<!--                    AESTIMAVI CODE. ITV DOESN`T USE CATEGORIES IN FORMS-->
<!--                    <base-table-includable ref="categories_table" :page="page" relation_name="questioncategories" @create="createCategory" @edit="editCategory" @change="getCategories"/>-->
                        <base-table-includable ref="questions_table" :page="page" relation_name="formquestions" @create="createQuestion" @edit="editQuestion" @change="loadForm"/>
                      </v-expansion-panels>
                    </v-tab-item>
                  </v-tabs>
                </v-card-text>
              </v-card>
            </v-col>
            <v-divider vertical/>
            <v-col cols="2">
              <v-card flat class="sticky">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <div>
                        <p>{{ $store.getters.translate("forms") }}</p>
                        <v-treeview
                            transition
                            item-key="name"
                            dense
                            :items="forms"
                            item-text="name">
                          <template v-slot:label="{ item }">
                            <a @click="insertForm(item)">{{ $store.getters.translate(item.name) }}</a>
                          </template>
                        </v-treeview>
                        <v-divider/>
                        <p class="mt-5">{{ $store.getters.translate("fields") }}</p>
                        <v-text-field
                            :class="'ma-0 pt-'+$store.getters.lineheight"
                            v-model="search"
                            :label="$store.getters.translate('search')"
                            flat
                            hide-details
                            clearable
                            clear-icon="mdi-close-circle-outline"
                            prepend-icon="mdi-magnify"/>
                        <v-treeview
                          :search="search"
                          transition
                          item-key="name"
                          dense
                          :items="template_fields"
                          item-text="name">
                          <template v-slot:label="{ item }">
                            <a @click="insertContent(item)">{{ $store.getters.translate(item.name) }}</a>
                          </template>
                        </v-treeview>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-card flat style="height: 100%">
            <v-card-text style="height: 100%">
              <v-layout v-if="!preview_url" align-center justify-center column fill-height>
                <v-flex row align-center>
                  <div class="justify-center">
                    <v-progress-circular :size="100" color="primary" indeterminate/>
                    <transition name="fade">
                      <h3 class="mt-5">{{ $store.getters.translate("loading") }}</h3>
                    </transition>
                  </div>
                </v-flex>
              </v-layout>
              <p v-else align="center" style="height: 100%" class="mt-5">
                <iframe :key="key" style="overflow: hidden; height: 1000px; width: 100%" height="100%" width="800px" :src="preview_url" frameborder="0"/>
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat style="height: 100%">
            <v-card-text style="height: 100%">
              <v-layout v-if="!preview_url" align-center justify-center column fill-height>
                <v-flex row align-center>
                  <div class="justify-center">
                    <v-progress-circular :size="100" color="primary" indeterminate/>
                    <transition name="fade">
                      <h3 class="mt-5">{{ $store.getters.translate("loading") }}</h3>
                    </transition>
                  </div>
                </v-flex>
              </v-layout>
              <p v-else align="center" style="height: 100%" class="mt-5">
                <iframe :key="key" style="overflow: hidden; height: 1000px; width: 100%" height="100%" width="800px" :src="preview_url" frameborder="0"/>
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <create-form ref="create_form" modal_name="create_form" :keep_open="true" @refresh="getForms"/>
    <create-formquestion ref="create_question" modal_name="create_question" @refresh="loadForm" :parent="selected_form" :categories="categories"/>
    <edit-formquestion ref="edit_question" modal_name="edit_question" @refresh="loadForm" :parent="selected_form" :categories="categories"/>
<!--    AESTIMAVI CODE. ITV DOESN`T USE CATEGORIES IN FORMS-->
<!--    <create-questioncategory ref="create_category" modal_name="create_category" @refresh="getCategories"/>-->
<!--    <edit-questioncategory ref="edit_category" modal_name="edit_category" @refresh="getCategories"/>-->
<!--    <base-modal-preview ref="base_modal_preview" modal_name="base-modal-preview" :pdfSource="pdfSource"/>-->
  </v-container>
</template>

<script>
import CreateFormquestion from "../forms/formquestions/Create";
import EditFormquestion from "../forms/formquestions/Edit";
//import CreateQuestioncategory from "../forms/questioncategories/Create";
//import EditQuestioncategory from "../forms/questioncategories/Edit";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
//import BaseModalPreview from "../../components/commonComponents/BaseModalPreview";
import BaseEditor from "../../components/commonComponents/BaseEditor";
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import CreateForm from "../forms/Create";
import store from "../../store";

export default {
  components: {
    BaseTableIncludable,
    BaseBreadcrumb,
    BaseModal,
    BaseDraggableModal,
    //BaseModalPreview,
    CreateFormquestion,
    EditFormquestion,
    //CreateQuestioncategory,
    //EditQuestioncategory,
    CreateForm,
    BaseEditor
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("templates"),
        name: "templates",
        model: "template",
      },
      record: {
        name: "",
        html_nl: "",
        html_en: "",
        margin_bottom: 0,
        margin_top: 0,
        margin_left: 0,
        margin_right: 0,
        custom_fields: {}
      },
      forms: [],
      categories: [],
      template_fields: [],
      usual_fields: [],
      custom_fields: [],
      selected_form: {
        id: null,
      },
      tags_required: false,
      loading: false,
      search: null,
      tab: 0,
      file: null,
      fields: [],
      key: 0,
      preview_url: null,
      language: 'nl',
      //pdfSource: null,
      form_headers: [
        { text: store.getters.translate("id"), value: "id", align: "start", sortable: true },
        { text: store.getters.translate("name"), value: "name", align: "start", sortable: true },
        { text: store.getters.translate("updated"), value: "updated_at" },
      ],
    };
  },
  created() {
    this.load();
    this.getTemplateFields();
    this.getForms();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
        .then((response) => {
          this.record = response.data;
          if (this.record.custom_fields == null || Array.isArray(this.record.custom_fields) || typeof this.record.custom_fields != "object") {
            this.record.custom_fields = {};
          }
          this.loading = false;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    save() {
      if(!this.loading) {
        let error = false;
        this.usual_fields.forEach((field) => {
          if (field.mandatory && field.field_type !== 'boolean' && (!this.record[field.name] || this.record[field.name] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
            error = true;
          }
        });
        if (!error) {
          this.hideHeader();
          this.hideFooter();
          this.loading = true;
          this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure?")).then((res) => {
          if (res) {
            this.loading = true;
            this.$http
              .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
              .then(() => {
                this.$router.push("/" + this.page.name);
                this.loading = false;
                this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
          }
        }
      );
    },
    async getTemplateFields() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields")
          .then((response) => {
            this.template_fields = this.$lodash
                .chain(response.data)
                .groupBy("model_type")
                .map((value, key) => ({
                  name: this.$store.getters.translate(key.toLowerCase()),
                  children: value,
                }))
                .value();
            this.template_fields = this.$lodash.sortBy(this.template_fields, "name");
            this.template_fields.map(function (value) {
              if (value.name === 'exam') {
                value.children.push({
                  id: 0,
                  name: "candidates",
                  model_type: "Exam",
                  source: null
                })
              }
            });
            this.getFields();
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    saveImage() {
      const formData = new FormData();
      formData.append("background_image", this.file, "name.jpeg");
      this.$http
        .post(this.$store.getters.appUrl + "v2/templates/file?id=" + this.record.id, formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then(() => {
          this.load();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteImage() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/templates/file/delete?id=" + this.record.id)
        .then(() => {
          this.load();
          this.file = null;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setLanguage(language) {
      this.language = language;
    },
    preview(language) {
      this.loading = true;
      this.record.language = language;
      this.$http
        .post(this.$store.getters.appUrl + "v2/pdf/preview", this.record)
        .then((response) => {
          this.record.language = null;
          //this.pdfSource = response.data;
          this.loading = false;
          this.key = this.key + 1;
          this.preview_url = response.data;
          //if (this.pdfSource) {
            //this.$refs.base_modal_preview.openModal("base-modal-preview");
          //}
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    insertContent(item) {
      if (item.name) {
        let model_type = item.model_type.toLowerCase() + ".";
        let source = item.source ? item.source.toLowerCase() + "." : "";
        let name = item.name.toLowerCase();
        if (this.language === 'en') {
          this.$refs.rte_main_en.$refs.text_editor.editor.insertContent("${" + model_type + source + name + "}");
        }
        else {
          this.$refs.rte_main_nl.$refs.text_editor.editor.insertContent("${" + model_type + source + name + "}");
        }
      }
    },
    insertForm(item) {
      if (this.language === 'en') {
        this.$refs.rte_main_en.$refs.text_editor.editor.insertContent("${form-" + item.id + "}");
      }
      else {
        this.$refs.rte_main_nl.$refs.text_editor.editor.insertContent("${form-" + item.id + "}");
      }
    },
    loadForm(form = null) {
      let form_id = null;
      if(form) {
        form_id = form.id;
      }
      else {
        form_id = this.selected_form.id;
      }
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/forms/" + form_id)
          .then((response) => {
            this.loading = false;
            this.selected_form = response.data;
            this.$refs.questions_table.records = this.$lodash.sortBy(this.selected_form.questions, "sort_order");
            this.$refs.questions_table.updateHeaders('form_question', null);
            this.$refs.questions_table.selected = [];
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    async getForms() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/forms")
          .then((response) => {
            this.forms = response.data.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    async getCategories() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/questioncategories")
          .then((response) => {
            this.loading = false;
            this.categories = response.data;
            //this.$refs.categories_table.records = this.categories;
            //this.$refs.categories_table.updateHeaders('question_category', null);
            //this.$refs.categories_table.selected = [];
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    createForm() {
      this.$refs.create_form.openModal('create_form');
    },
    createQuestion() {
      this.$refs.create_question.openModal('create_question');
    },
    editQuestion(item) {
      this.$refs.edit_question.openModal('edit_question');
      this.$refs.edit_question.load(item.id);
    },
    //AESTIMAVI CODE. ITV DOESN`T USE CATEGORIES IN FORMS
    /*createCategory() {
      this.$refs.create_category.openModal('create_category');
    },
    editCategory(item) {
      this.$refs.edit_category.openModal('edit_category');
      this.$refs.edit_category.load(item.id);
    },*/
    showHeader() {
      this.$refs.modal_header.open();
    },
    showFooter() {
      this.$refs.modal_footer.open();
    },
    hideHeader() {
      this.$refs.modal_header.close();
    },
    hideFooter() {
      this.$refs.modal_footer.close();
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_update=true&model_type=" + this.page.model)
          .then((response) => {
            this.custom_fields = [];
            this.usual_fields = [];
            response.data.data.forEach((field) => {
              if (field.custom_field == 1) {
                this.custom_fields.push(field);
              }
              else if(field.name === 'tags') {
                this.tags_required = true;
              }
              else {
                if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    canEdit() {
      return this.$can("edit", this.page.model);
    },
    canDelete() {
      return this.$can("delete", this.page.model);
    },
  },
  computed: {
    margins() {
      return {
        margin_top: this.record.margin_top,
        margin_bottom: this.record.margin_bottom,
        margin_left: this.record.margin_left,
        margin_right: this.record.margin_right,
      };
    },
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 30px;
}
</style>